import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { flybuysConfig } from '@/config/flybuys';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './loyalty-query-keys';

import type { FlybuysTypes } from '../types';
import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

type TFlybuysGetTokensPayload = {
  auth_config: FlybuysTypes.TLoyaltyAuthConfig;
  code: string;
  code_verifier: string;
};

export function useFlybuysGetTokens(
  options?: UseMutationOptions<
    FlybuysTypes.TFlybuysGetTokensResponse,
    TBaasError,
    TFlybuysGetTokensPayload,
    unknown
  >
) {
  const auth = useAuth();

  const flybuysGetTokens = async (payload: TFlybuysGetTokensPayload) => {
    const { auth_config, code, code_verifier } = payload;
    const { audience, client_id, endpoints, scope } = auth_config;

    return await request<FlybuysTypes.TFlybuysGetTokensResponse>(
      endpoints.token,
      auth,
      {
        data: {
          audience,
          client_id,
          code,
          code_verifier,
          grant_type: flybuysConfig.auth.token.grant_type,
          method: RequestMethod.POST,
          redirect_uri: flybuysConfig.auth.token.redirect_uri,
          scope,
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: LoyaltyEndpointKeys.FLYBUYS_GET_TOKENS,
        transaction: loyaltyTransactionKeys.flybuysGetTokens,
      }
    );
  };

  return useMutation({
    mutationFn: flybuysGetTokens,
    mutationKey: loyaltyQueryKeys.flybuysGetTokens,
    ...options,
  });
}
