import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './loyalty-query-keys';

import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

type TFlybuysCredentialsPayload = {
  access_token: string;
  refresh_token: string;
};

export function useFlybuysUpdateCredentials(
  options?: UseMutationOptions<
    unknown,
    TBaasError,
    TFlybuysCredentialsPayload,
    unknown
  >
) {
  const auth = useAuth();

  const flybuysCredentials = async (payload: TFlybuysCredentialsPayload) =>
    await request(
      endpoints.FLYBUYS_CREDENTIALS,
      auth,
      {
        data: payload,
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P3,
        requestKey: LoyaltyEndpointKeys.FLYBUYS_CREDENTIALS,
        transaction: loyaltyTransactionKeys.flybuysCredentials,
      }
    );

  return useMutation({
    mutationFn: flybuysCredentials,
    mutationKey: loyaltyQueryKeys.flybuysCredentials,
    ...options,
  });
}
