export enum LoyaltyEndpointKeys {
  FLYBUYS_CARDS = 'FLYBUYS_CARDS',
  FLYBUYS_COMPLETE_LINK = 'FLYBUYS_COMPLETE_LINK',
  FLYBUYS_CREDENTIALS = 'FLYBUYS_CREDENTIALS',
  FLYBUYS_DELETE = 'FLYBUYS_DELETE',
  FLYBUYS_GET_TOKENS = 'FLYBUYS_GET_TOKENS',
  FLYBUYS_INITIATE = 'FLYBUYS_INITIATE',
  LOYALTY_CARDS = 'LOYALTY_CARDS',
  LOYALTY_CARD_ADD = 'LOYALTY_CARD_ADD',
  LOYALTY_CARD_DELETE = 'LOYALTY_CARD_DELETE',
  MYCOLES_CARDS = 'MYCOLES_CARDS',
}

export const loyaltyQueryKeys = {
  flybuysCards: ['flybuys-cards'],
  flybuysCompleteLink: ['flybuys-complete-link'],
  flybuysCredentials: ['flybuys-credentials'],
  flybuysDelete: ['flybuys-delete'],
  flybuysGetTokens: ['flybuys-get-tokens'],
  flybuysInitiate: ['flybuys-initiate'],
  loyaltyCardAdd: ['loyalty-card-add'],
  loyaltyCards: ['loyalty-cards'],
  loyaltyCardsDelete: ['loyalty-cards-delete'],
  mycolesCards: ['mycoles-cards'],
};

export const loyaltyTransactionKeys = {
  flybuysCards: 'Get Flybuys Cards',
  flybuysCompleteLink: 'Complete Flybuys Link',
  flybuysCredentials: 'Get Flybuys Credentials',
  flybuysDelete: 'Delete Flybuys Card',
  flybuysGetTokens: 'Get Flybuys Tokens',
  flybuysInitiate: 'Initiate Flybuys Link',
  loyaltyCardAdd: 'Add Loyalty Card',
  loyaltyCardDelete: 'Delete Loyalty Card',
  loyaltyCards: 'Get Loyalty Cards',
  mycolesCards: 'Get mycoles Cards',
};
