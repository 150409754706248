import * as Sentry from '@sentry/react';
import isEmpty from 'lodash.isempty';

import { authClientId } from '@/services/auth';
import { useProfileStore } from '@/store';

import { EventLevel } from './enums';

import type { EventPriority } from './enums';

type TTags = {
  correlation_id?: string;
  error_message?: string;
  error_number?: number;
  priority: `${EventPriority}`;
};

type TContext = {
  level?: `${EventLevel}`;
  tags: TTags;
};

export const sentry = {
  captureError(transactionName: string, exception: string, context: TContext) {
    const walletId = useProfileStore.getState().currentWalletId;
    Sentry.withScope((scope) => {
      scope.setTransactionName(transactionName);
      scope.setTags({
        ...context.tags,
        client_id: authClientId,
        wallet_id: walletId,
      });

      Sentry.captureException(new Error(exception), {
        level: context?.level || EventLevel.ERROR,
      });
    });
  },

  captureLog(
    transactionName: string,
    message: string,
    context?: Record<string, unknown>
  ) {
    const walletId = useProfileStore.getState().currentWalletId;

    Sentry.withScope((scope) => {
      scope.setTransactionName(transactionName);

      scope.setTags({
        ...(!!context?.tags && !isEmpty(context?.tags) && context.tags),
        wallet_id: walletId,
      });

      Sentry.captureException(message, {
        level: EventLevel.LOG,
        ...(!isEmpty(context) && context),
      });
    });
  },

  captureMessage(message: string, context: Record<string, unknown>) {
    if (!!message) {
      Sentry.captureMessage(message, context);
    }
  },
};
