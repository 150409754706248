import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './loyalty-query-keys';

import type { FlybuysTypes } from '../types';
import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

type TFlybuysCompleteLinkPayload = {
  access_token: string;
  refresh_token: string;
};

export function useFlybuysCompleteLink(
  options?: UseMutationOptions<
    FlybuysTypes.TFlybuysCompleteLinkResponse,
    TBaasError,
    TFlybuysCompleteLinkPayload,
    unknown
  >
) {
  const auth = useAuth();

  const flybuysCompleteLink = async (payload: TFlybuysCompleteLinkPayload) =>
    await request<FlybuysTypes.TFlybuysCompleteLinkResponse>(
      endpoints.FLYBUYS_COMPLETE_LINK,
      auth,
      {
        data: payload,
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: LoyaltyEndpointKeys.FLYBUYS_COMPLETE_LINK,
        transaction: loyaltyTransactionKeys.flybuysCompleteLink,
      }
    );

  return useMutation({
    mutationFn: flybuysCompleteLink,
    mutationKey: loyaltyQueryKeys.flybuysCompleteLink,
    ...options,
  });
}
