import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from './endpoints';
import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './loyalty-query-keys';

import type { FlybuysTypes } from '../types';
import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useFlybuysInitiateLink(
  options?: UseMutationOptions<
    FlybuysTypes.TFlybuysInitiateLinkResponse,
    TBaasError,
    void,
    unknown
  >
) {
  const auth = useAuth();

  const flybuysInitiateLink = async () =>
    await request<FlybuysTypes.TFlybuysInitiateLinkResponse>(
      endpoints.FLYBUYS_INITIATE_LINK,
      auth,
      {
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P3,
        requestKey: LoyaltyEndpointKeys.FLYBUYS_INITIATE,
        transaction: loyaltyTransactionKeys.flybuysInitiate,
      }
    );

  return useMutation({
    mutationFn: flybuysInitiateLink,
    mutationKey: loyaltyQueryKeys.flybuysInitiate,
    ...options,
  });
}
